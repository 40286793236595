<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-row class="pa-0 ma-0" justify="center">
            <v-col cols="12" class="pa-0 ma-0">
                <datatable
                        :api-endpoint="this.ENDPOINT.DATATABLES.ERP.STOCKTAKING.OPEN"
                        :custom-buttons="this.custombuttons"
                        :datatable-headers="this.datatableHeaders"
                        :elevation="0"
                        :excel-columns="excelColumns"
                        :v-show="true"
                        @close="close"
                        @deleteEntries="deleteEntries"
                        @deleteEntry="deleteEntry"
                        @displayEntry="displayEntry"
                        @scanEntry="scanEntry"
                        excel-file-name="stocktaking-open"
                        ref="stocktaking"
                        show-delete-buttons
                        show-display-buttons
                        show-scan-buttons
                        show-select-checkbox
                />
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    //components
    import Datatable from "../../../datatable/Datatable";
    //configs
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";

    export default {
        name: "StockTakingComponent",
        components: {Datatable},
        data: () => {
            return {
                ENDPOINT: ENDPOINTS,
                custombuttons: [
                    {
                        text: "check",
                        color: "error",
                        eventRow: "close",
                        layout: {
                            outlined: false,
                            flat: false,
                            text: false,
                            icon: true
                        }
                    }
                ]
            }
        },
        methods: {
            delete(idsToDelete) {
                this.$swal({
                    title: this.$t("erp.deleteOpenStockTakingHeader"),
                    text: this.$t("erp.deleteOpenStockTakingBody"),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.axios.post(ENDPOINTS.ERP.STOCKTAKING.DELETE, {
                            stockTakingID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.stockTakingDeleted"),
                                    color: "success"
                                });


                                this.$refs.stocktaking.getDataFromApi();
                                this.$refs.stocktaking.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    }
                });
            },
            deleteEntry(entry) {
                this.delete([entry.stockTakingID]);
            },
            deleteEntries(ids) {
                this.delete(ids);
            },
            displayEntry(entry) {
                this.$router.replace((this.$route.fullPath + '/details/' + entry.stockTakingID).replace('//', '/'))
            },
            scanEntry(entry) {
                this.$router.replace('/erp/storage/stockTaking/' + entry.stockTakingID + '/create');
            },
            close(item) {
                this.$swal({
                    title: this.$t("erp.completeStocktakingHeader"),
                    text: this.$t("erp.completeStocktakingBody"),
                    icon: "warning",
                    confirmButtonText: this.$t("generic.lang_apply"),
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.axios.post(ENDPOINTS.ERP.STOCKTAKING.UPDATE, {
                            stockTakingID: item.stockTakingID
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {

                                this.$refs.stocktaking.getDataFromApi();

                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_actionSuccessful'),
                                    color: "success"
                                });
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    }
                });
            }

        },
        computed: {
            datatableHeaders: function () {
                return [
                    {
                        text: this.$t("erp.lang_id"),
                        align: "left",
                        value: "stockTakingID",
                        width: 80,
                        hide: true
                    },
                    {text: this.$t("erp.stocktakingName"), value: "stockTakingName"},
                    {
                        text: this.$t("erp.lang_storageName"),
                        value: "storageName",
                        sortable: false
                    }
                ];
            },
            excelColumns: function () {
                return [
                    {
                        label: this.$t("erp.lang_storageID"),
                        field: "id"
                    },
                    {label: this.$t("erp.lang_storageName"), field: "storageName"},
                    {
                        label: this.$t("generic.lang_street"),
                        field: "storageStreet"
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>