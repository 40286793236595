<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-card class="pa-0 ma-0 transparent" elevation="0">
            <v-card-title class="pa-0 ma-0 pt-5 pb-5">
                <div  class="transparent">
                    <v-btn @click="tabs = 0" :elevation="this.tabs == 0? '12' : '0'" :class="this.tabs != 0? 'transparent' : ''" :color="this.tabs == 0? 'primary' : ''" >
                        {{this.$t('erp.OngoingStockTakings').split(' ')[0]}}</v-btn>
                    <v-btn  @click="tabs = 1" :elevation="this.tabs == 1? '12' : '0'" :class="this.tabs != 1? 'transparent' : ''" :color="this.tabs == 1? 'primary' : ''" >{{this.$t('erp.completedStockTakings').split(' ')[0]}}</v-btn>
                </div>

            </v-card-title>
            <v-card-text class="pa-0 ma-0 elevation-3">
                <v-row class="pa-0 ma-0" justify="center">
                    <v-col class="pa-0 ma-0" cols="12">
                        <v-tabs-items  v-model="tabs">
                            <v-tab-item>
                                <open-stock/>
                            </v-tab-item>
                            <v-tab-item>
                                <close-stock/>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    //components
    import Datatable from "../../../datatable/Datatable";
    import OpenStock from "./OpenStockComponent";
    import CloseStock from "./CloseStockComponent";


    export default {
        name: "StockTakingComponent",
        components: {Datatable, OpenStock, CloseStock},
        data: () => {
            return {
                tabs: 0,
            }
        }
    }
</script>

<style scoped>
    .v-btn__content , .v-btn {
        text-transform: none !important;
    }
</style>