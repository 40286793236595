<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-row class="pa-0 ma-0" justify="center">
            <v-col class="pa-0 ma-0" cols="12">
                <datatable
                        :api-endpoint="this.ENDPOINT.DATATABLES.ERP.STOCKTAKING.CLOSE"
                        :datatable-headers="this.datatableHeaders"
                        :elevation="0"
                        :excel-columns="excelColumns"
                        :v-show="true"
                        @displayEntry="displayEntry"
                        @downloadExcel="downloadExcel"
                        excel-file-name="stocktaking-close"
                        ref="stocktaking_close"
                        show-display-buttons
                        show-excel-buttons
                />
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    //components
    import Datatable from "../../../datatable/Datatable";
    //configs
    import {ENDPOINTS} from "../../../../config";
    import {mapState} from "vuex";

    export default {
        name: "StockTakingComponent",
        components: {Datatable},
        data: () => {
            return {
                ENDPOINT: ENDPOINTS,

            }
        },
        methods: {
            displayEntry(entry) {
                this.$router.replace((this.$route.fullPath + '/details/' + entry.stockTakingID).replace('//', '/'))
            },
          downloadExcel(entry) {
            let apiServerIP = process.env.VUE_APP_API_HOST;
            if(window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
              apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
            }
            window.open(apiServerIP + "/get/reports/lists/stockTaking/?&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken+"&id="+entry.stockTakingID, "_blank");
          },
        },
        computed: {
          ...mapState([
            'api'
          ]),
            datatableHeaders: function () {
                return [
                    {
                        text: this.$t("erp.lang_id"),
                        align: "left",
                        value: "stockTakingID",
                        width: 80,
                        hide: true
                    },
                    {text: this.$t("erp.stocktakingName"), value: "stockTakingName"},
                    {
                        text: this.$t("erp.lang_storageName"),
                        value: "storageName",
                        sortable: false
                    }
                ];
            },
            excelColumns: function () {
                return [
                    {
                        label: this.$t("erp.lang_storageID"),
                        field: "id"
                    },
                    {label: this.$t("erp.lang_storageName"), field: "storageName"},
                    {
                        label: this.$t("generic.lang_street"),
                        field: "storageStreet"
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>